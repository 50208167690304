import React from "react";
import styled from "styled-components";
import PageContainer from "../components/PageContainer";

import the_three_billy_goats_gruff_0 from "../images/illustrations/the_three_billy_goats_gruff_0.jpg";
import the_three_billy_goats_gruff_1 from "../images/illustrations/the_three_billy_goats_gruff_1.jpg";
import the_three_billy_goats_gruff_2 from "../images/illustrations/the_three_billy_goats_gruff_2.jpg";
import the_three_billy_goats_gruff_3 from "../images/illustrations/the_three_billy_goats_gruff_3.jpg";
import all_ears_all_eyes_0 from "../images/illustrations/all_ears_all_eyes_0.jpg";
import all_ears_all_eyes_1 from "../images/illustrations/all_ears_all_eyes_1.jpg";
import all_ears_all_eyes_2 from "../images/illustrations/all_ears_all_eyes_2.jpg";
import all_ears_all_eyes_3 from "../images/illustrations/all_ears_all_eyes_3.jpg";
import shoe_dog_0 from "../images/illustrations/shoe_dog_0.jpg";
import shoe_dog_1 from "../images/illustrations/shoe_dog_1.jpg";
import all_the_water_in_the_world_0 from "../images/illustrations/all_the_water_in_the_world_0.jpg";
import all_the_water_in_the_world_1 from "../images/illustrations/all_the_water_in_the_world_1.jpg";
import all_the_water_in_the_world_2 from "../images/illustrations/all_the_water_in_the_world_2.jpg";
import all_the_water_in_the_world_3 from "../images/illustrations/all_the_water_in_the_world_3.jpg";
import its_picture_day_today_0 from "../images/illustrations/its_picture_day_today_0.jpg";
import its_picture_day_today_1 from "../images/illustrations/its_picture_day_today_1.jpg";
import Head from "../components/Head";

const images = [
  {
    original: the_three_billy_goats_gruff_0,
    description: "The Three Billy Goats Gruff",
  },
  {
    original: the_three_billy_goats_gruff_1,
    description: "The Three Billy Goats Gruff",
  },
  {
    original: the_three_billy_goats_gruff_2,
    description: "The Three Billy Goats Gruff",
  },
  {
    original: the_three_billy_goats_gruff_3,
    description: "The Three Billy Goats Gruff",
  },
  {
    original: all_ears_all_eyes_0,
    description: "All Ears All Eyes",
  },
  {
    original: all_ears_all_eyes_1,
    description: "All Ears All Eyes",
  },
  {
    original: all_ears_all_eyes_2,
    description: "All Ears All Eyes",
  },
  {
    original: all_ears_all_eyes_3,
    description: "All Ears All Eyes",
  },
  {
    original: shoe_dog_0,
    description: "Shot Dog",
  },
  {
    original: shoe_dog_1,
    description: "Shot Dog",
  },
  {
    original: all_the_water_in_the_world_0,
    description: "All the Water in the World",
  },
  {
    original: all_the_water_in_the_world_1,
    description: "All the Water in the World",
  },
  {
    original: all_the_water_in_the_world_2,
    description: "All the Water in the World",
  },
  {
    original: all_the_water_in_the_world_3,
    description: "All the Water in the World",
  },
  {
    original: its_picture_day_today_0,
    description: "It's Picture Day Today",
  },
  {
    original: its_picture_day_today_1,
    description: "It's Picture Day Today",
  },
];

const CarouselContainer = styled.div`
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 45px;

  @media (min-width: 769px) {
    margin-top: 0;
  }

  @media (min-width: 1000px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const ImageContainer = styled.a`
  display: block;
  position: relative;
  width: 343px;
  height: 343px;
  overflow: hidden;
  margin: 16px;

  &::after {
    color: white;
    font-style: bold;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    content: "${(props) => props.imageTitle}";
    // content: ${(props) => props.imageTitle};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    transform: translateY(45px);
    transition: transform 0.2s ease-in-out;
  }

  &:hover::after {
    transform: translateY(0);
  }

  & img {
    display: block;
    max-height: 343px;
    max-width: 343px;
    width: 343px;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
  }
`;

function IllustrationPage() {
  return (
    <>
      <Head title="Katherine Tillotson - Illustration" />
      <PageContainer hideSiteTitleForDesktop>
        <CarouselContainer>
          {images.map((image, index) => (
            <ImageContainer
              key={`illustration-${index}`}
              imageTitle={image.description}
              href={image.original}
            >
              <img src={image.original} alt={image.description} />
            </ImageContainer>
          ))}
        </CarouselContainer>
      </PageContainer>
    </>
  );
}

export default IllustrationPage;
